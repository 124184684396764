import {CURRENT_LS_VERSIONS, deleteAllLSItems, getAllLSItems, setLSItem, TLSItem, TLSItemWithKey, TLSKey} from "./index"

type TVersionUpdater = {
  fromKey: TLSKey | string
  fromVersion: number
  /**
   * Return `null` to remove the item
   */
  migrateFn: (old: TLSItem) => TLSItemWithKey | null
}

export const UPDATERS: TVersionUpdater[] = [
  // Language 'en' is now 'en-gb' for date formatting reasons
  {
    fromKey: "language",
    fromVersion: 1,
    migrateFn: ({data}) => ({version: 2, key: "language", data: data === "en" ? "en-gb" : data}),
  },

  // Users now have a 'masqueraded' flag
  {
    fromKey: "lastUser",
    fromVersion: 1,
    migrateFn: ({data}) => ({version: 2, key: "lastUser", data: {...data, masqueraded: false}}),
  },
]

export function updateAllLSItems(updaters: TVersionUpdater[] = UPDATERS) {
  const allItems = getAllLSItems()

  deleteAllLSItems()

  allItems.forEach(item => {
    const updatedItem = getUpdatedLSItem({updaters, item})

    if (!updatedItem || updatedItem.version !== CURRENT_LS_VERSIONS[updatedItem.key as TLSKey]) {
      return
    }

    setLSItem(updatedItem.key as TLSKey, updatedItem.data, updatedItem.version)
  })
}

function getUpdatedLSItem({
  updaters,
  item,
}: {
  updaters: TVersionUpdater[]
  item: TLSItemWithKey
}): TLSItemWithKey | null {
  const updater = updaters.find(u => u.fromKey === item.key && u.fromVersion === item.version)

  if (!updater) {
    return item
  }

  const updatedItem = updater.migrateFn(item)
  return updatedItem && getUpdatedLSItem({updaters, item: updatedItem})
}
