import {pick} from "lodash"

import {
  ASaasCompanyUser,
  ASettings,
  AUsersCompanyUser,
  AUsersSalesPerson,
  AUserTypes,
} from "../services/types.generated"

export const isCompanyUser = (user: ASettings["user"]): user is AUsersCompanyUser => {
  return user.type === AUserTypes.CompanyUser && "company" in user
}

export const isSaaSCompanyUser = (user: ASettings["user"]): user is ASaasCompanyUser => {
  return user.type === AUserTypes.SaasCompanyUser && "company" in user
}

export const isSalesPersonUser = (user: ASettings["user"]): user is AUsersSalesPerson => {
  return user.type === AUserTypes.SalesPerson
}

export type TPickPropsShape<T extends Record<any, any>> = {[prop in keyof Required<T>]: true}

export function pickProps<T extends Record<any, any>>(shape: TPickPropsShape<T>) {
  return (allProps: T & Record<any, any>) => pick(allProps, Object.keys(shape)) as unknown as T
}
