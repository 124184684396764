import React from "react"
import {createBrowserRouter, RouterProvider} from "react-router-dom"

import {LoginGate, LogoutGate, UserTypeCrossroads} from "./components/authGates"
import Layout from "./components/Layout"
import {stepCompanyProfile, stepCompanyTransactions} from "./routes/Company/stepsConfig.ts"
import {PasswordResetRequest} from "./routes/ForgottenPassword/PasswordResetRequest.tsx"
import {PasswordResetVerification} from "./routes/ForgottenPassword/PasswordResetVerification.tsx"
import Landing from "./routes/Landing"
import {LogIn} from "./routes/LogIn.tsx"
import {
  stepAdditionalInformation,
  stepBenefits,
  stepElevatorPitch,
  stepMarketPresence,
  stepReferences,
  stepUniqueness,
  stepWeb,
} from "./routes/SalesStrategy/stepsConfig.ts"
import SignUp from "./routes/SignUp"
import {AccountCreatePage} from "./routes/SignUp/Company/AccountCreatePage.tsx"
import {EmailFormPage} from "./routes/SignUp/Company/EmailFormPage.tsx"
import Email from "./routes/SignUp/Email.tsx"
import Pick from "./routes/SignUp/Pick.tsx"
import Sent from "./routes/SignUp/Sent.tsx"
import {Verification} from "./routes/Verification.tsx"
import {AUserTypes} from "./services/types.generated"
import {RouterError} from "./RouterError"
import {rollbar} from "./services"

function lazyLoad<T extends Promise<Record<any, any>>, K extends keyof Awaited<T>>(
  module: () => T,
  member: K,
  ...preloadGroup: Array<() => Promise<unknown>>
): React.LazyExoticComponent<Awaited<T>[K]> & {load: () => Promise<{default: React.ComponentType}>} {
  const load = async () => {
    await Promise.all(preloadGroup.map(preload => preload()))

    const loaded = await module()

    return {default: loaded[member]}
  }

  return Object.assign(
    React.lazy(async () => {
      try {
        return await load()
      } catch (e) {
        rollbar.warn("Lazy loading failed. Was a new version just deployed?", e as Error)
        window.location.reload()

        return {default: (() => null) as Awaited<T>[K]}
      }
    }),
    {load}
  )
}

const LogOut = lazyLoad(() => import("./routes/LogOut.tsx"), "default")
const GDPR = lazyLoad(() => import("./routes/GDPR/index.tsx"), "GDPR")
const Deleted = lazyLoad(() => import("./routes/GDPR/Deleted.tsx"), "Deleted")
const DashboardSaaS = lazyLoad(() => import("./routes/DashboardSaaS/DashboardSaaS.tsx"), "DashboardSaaS")
const DashboardCompany = lazyLoad(() => import("./routes/DashboardCompany/DashboardCompany.tsx"), "DashboardCompany")
const WelcomeScreen = lazyLoad(
  () => import("./routes/DashboardCompany/WelcomeScreen/WelcomeScreen.tsx"),
  "WelcomeScreen"
)
const HowItWorks = lazyLoad(() => import("./routes/HowItWorks/index.tsx"), "HowItWorks")
const DashboardSalesperson = lazyLoad(() => import("./routes/DashboardSalesperson/index.tsx"), "default")
const CompanyProspects = lazyLoad(() => import("./routes/Prospects/Company/index.tsx"), "CompanyProspects")
const SellingDetailsCompanyAndAdmin = lazyLoad(
  () => import("./routes/SellingDetails/CompanyAndAdmin/SellingDetails.tsx"),
  "SellingDetails"
)
const SellingDetailsSalesperson = lazyLoad(
  () => import("./routes/SellingDetails/Salesperson/SellingDetails.tsx"),
  "SellingDetails"
)
const NewsTab = lazyLoad(() => import("./routes/SellingDetails/shared/tabs/NewsTab.tsx"), "NewsTab")
const Reports = lazyLoad(() => import("./routes/Reports/index.tsx"), "Reports")
const DetailsTab = lazyLoad(() => import("./routes/SellingDetails/CompanyAndAdmin/tabs/DetailsTab.tsx"), "DetailsTab")
const SalesForm = lazyLoad(() => import("./routes/SalesForm/index.tsx"), "SalesForm")
const Completed = lazyLoad(() => import("./routes/SalesStrategy/Completed.tsx"), "default")
const SalesStrategy = lazyLoad(() => import("./routes/SalesStrategy/index.tsx"), "default")
const Web = lazyLoad(() => import("./routes/SalesStrategy/Web.tsx"), "default")
const ElevatorPitch = lazyLoad(() => import("./routes/SalesStrategy/ElevatorPitch.tsx"), "default")
const Benefits = lazyLoad(() => import("./routes/SalesStrategy/Benefits.tsx"), "default")
const Uniqueness = lazyLoad(() => import("./routes/SalesStrategy/Uniqueness.tsx"), "default")
const References = lazyLoad(() => import("./routes/SalesStrategy/References.tsx"), "default")
const MarketPresence = lazyLoad(() => import("./routes/SalesStrategy/MarketPresence.tsx"), "default")
const AdditionalInformation = lazyLoad(
  () => import("./routes/SalesStrategy/AdditionalInformation.tsx"),
  "AdditionalInformation"
)
const CompanyPreview = lazyLoad(() => import("./routes/Company/CompanyPreview.tsx"), "CompanyPreview")
const MyCompanyGate = lazyLoad(() => import("./routes/Company/MyCompanyGate.tsx"), "MyCompanyGate")
const Company = lazyLoad(() => import("./routes/Company/index.tsx"), "Company")
const CompanyProfile = lazyLoad(() => import("./routes/Company/CompanyProfile.tsx"), "CompanyProfile")
const CompanyTransactions = lazyLoad(() => import("./routes/Company/CompanyTransactions.tsx"), "CompanyTransactions")
const SalespersonPreview = lazyLoad(() => import("./routes/Salesperson/SalespersonPreview.tsx"), "SalespersonPreview")
const MySalespersonGate = lazyLoad(() => import("./routes/Salesperson/MySalespersonGate.tsx"), "MySalespersonGate")
const Salesperson = lazyLoad(() => import("./routes/Salesperson/index.tsx"), "Salesperson")
const SalespersonLearning = lazyLoad(
  () => import("./routes/Salesperson/SalespersonLearning.tsx"),
  "SalespersonLearning"
)
const AdminIndex = lazyLoad(() => import("./routes/Admin/index.tsx"), "AdminIndex")
const AdminProspects = lazyLoad(() => import("./routes/Prospects/Admin/index.tsx"), "AdminProspects")
const SaasCompanyProspects = lazyLoad(() => import("./routes/Prospects/SaasCompany/index.tsx"), "SaasCompanyProspects")
const Leads = lazyLoad(() => import("./routes/Leads/index.tsx"), "Leads")
const MatchingIndex = lazyLoad(() => import("./routes/Admin/Matching/index.tsx"), "MatchingIndex")
const Matching = lazyLoad(() => import("./routes/Admin/Matching/index.tsx"), "default")
const MatchingDetails = lazyLoad(() => import("./routes/Admin/Matching/Details/index.tsx"), "default")
const SellingsIndex = lazyLoad(() => import("./routes/Admin/Sellings/index.tsx"), "SellingsIndex")
const Sellings = lazyLoad(() => import("./routes/Admin/Sellings/index.tsx"), "default")
const AdminReports = lazyLoad(() => import("./routes/Reports/AdminReports.tsx"), "AdminReports")
const CreateSalesCycle = lazyLoad(() => import("./routes/CreateSalesCycle/CreateSalesCycle.tsx"), "CreateSalesCycle")

const router = createBrowserRouter([
  {
    path: "/",
    element: <Layout />,
    errorElement: <RouterError />,
    children: [
      {
        index: true,
        element: <Landing />,
      },
      {
        path: "log-in",
        element: <LogoutGate Component={LogIn} />,
      },
      {
        path: "log-out",
        element: <LogOut />,
      },
      {path: "sign-up/company", element: <LogoutGate Component={EmailFormPage} />},
      {
        path: "sign-up",
        element: <LogoutGate Component={SignUp} />,
        children: [
          {
            index: true,
            element: <Pick />,
          },
          {
            path: "sales",
            element: <Email type={AUserTypes.SalesPerson} />,
          },
          {
            path: "sent",
            element: <Sent />,
          },
        ],
      },
      {
        path: "forgotten-password",
        children: [
          {
            index: true,
            element: <PasswordResetRequest />,
          },
          {
            path: "verification",
            element: <PasswordResetVerification />,
          },
        ],
      },
      {
        path: "verification",
        children: [
          {
            path: "company",
            element: <LogoutGate Component={AccountCreatePage} />,
          },
          {
            path: "sales",
            element: <LogoutGate Component={Verification} type={AUserTypes.SalesPerson} />,
          },
        ],
      },
      {
        path: "privacy-request",
        children: [
          {
            index: true,
            Component: GDPR,
          },
          {path: "deleted", Component: Deleted},
        ],
      },
      {
        path: "create-selling/:salesCycleId/:page?/:orderId?",
        element: <LoginGate Component={CreateSalesCycle} userTypes={[AUserTypes.CompanyUser]} />,
      },
      {
        path: "dashboard/:tab?",
        element: (
          <UserTypeCrossroads
            signpost={{
              [AUserTypes.CompanyUser]: DashboardCompany,
              [AUserTypes.SalesPerson]: DashboardSalesperson,
              [AUserTypes.SaasCompanyUser]: DashboardSaaS,
              [AUserTypes.SaasSalesPerson]: DashboardSaaS,
            }}
          />
        ),
      },
      {path: "welcome", element: <LoginGate Component={WelcomeScreen} userTypes={[AUserTypes.CompanyUser]} />},
      {
        path: "how-it-works",
        element: <LoginGate Component={HowItWorks} userTypes={[AUserTypes.CompanyUser]} />,
      },
      {
        path: "prospects/:salesCycleId",
        element: <LoginGate Component={CompanyProspects} userTypes={[AUserTypes.CompanyUser]} />,
      },
      {
        path: "sellings/:salesCycleId",
        element: (
          <UserTypeCrossroads
            signpost={{
              [AUserTypes.CompanyUser]: SellingDetailsCompanyAndAdmin,
              [AUserTypes.SaasCompanyUser]: SellingDetailsCompanyAndAdmin,
              [AUserTypes.SaasSalesPerson]: SellingDetailsSalesperson,
              [AUserTypes.SalesPerson]: SellingDetailsSalesperson,
              [AUserTypes.Admin]: SellingDetailsCompanyAndAdmin,
            }}
          />
        ),
        children: [
          {
            path: "news",
            element: (
              <LoginGate
                Component={NewsTab}
                userTypes={[
                  AUserTypes.CompanyUser,
                  AUserTypes.SaasCompanyUser,
                  AUserTypes.SaasSalesPerson,
                  AUserTypes.SalesPerson,
                  AUserTypes.Admin,
                ]}
              />
            ),
          },
          {
            path: "reports/:metric?",
            element: (
              <LoginGate
                Component={Reports}
                userTypes={[AUserTypes.CompanyUser, AUserTypes.SaasCompanyUser, AUserTypes.Admin]}
              />
            ),
          },
          {
            path: "prospects",
            element: (
              <UserTypeCrossroads
                signpost={{
                  [AUserTypes.CompanyUser]: CompanyProspects,
                  [AUserTypes.Admin]: AdminProspects,
                  [AUserTypes.SaasCompanyUser]: SaasCompanyProspects,
                }}
              />
            ),
          },
          {
            path: "details",
            element: <LoginGate Component={DetailsTab} userTypes={[AUserTypes.CompanyUser, AUserTypes.Admin]} />,
          },
        ],
      },
      {
        path: "sales-form/:salesCycleId?",
        element: <LoginGate Component={SalesForm} userTypes={[AUserTypes.CompanyUser]} />,
      },
      {
        path: "sales-strategy-completed",
        element: <LoginGate Component={Completed} userTypes={[AUserTypes.CompanyUser]} />,
      },
      {
        path: "sales-strategy/:salesCycleId",
        element: <LoginGate Component={SalesStrategy} userTypes={[AUserTypes.CompanyUser]} />,
        children: [
          {
            path: stepWeb.href,
            element: <Web />,
          },
          {
            path: stepElevatorPitch.href,
            element: <ElevatorPitch />,
          },
          {
            path: stepBenefits.href,
            element: <Benefits />,
          },
          {
            path: stepUniqueness.href,
            element: <Uniqueness />,
          },
          {
            path: stepReferences.href,
            element: <References />,
          },
          {
            path: stepMarketPresence.href,
            element: <MarketPresence />,
          },
          {
            path: stepAdditionalInformation.href,
            element: <AdditionalInformation />,
          },
        ],
      },
      {
        path: "company/:companyId",
        element: <CompanyPreview />,
      },
      {
        path: "company/:companyId?",
        element: <MyCompanyGate Component={Company} redirectToCurrentIfUndef />,
        children: [
          {
            path: stepCompanyProfile.href,
            element: <CompanyProfile />,
          },
          {
            path: stepCompanyTransactions.href,
            element: <CompanyTransactions />,
          },
        ],
      },
      {
        path: "salesperson/:salespersonId",
        element: <SalespersonPreview />,
      },
      {
        path: "salesperson/:salespersonId?",
        children: [
          {
            path: "profile",
            element: <MySalespersonGate Component={Salesperson} redirectToCurrentIfUndef />,
          },
          {
            path: "learning-materials",
            element: <MySalespersonGate Component={SalespersonLearning} redirectToCurrentIfUndef />,
          },
        ],
      },
      {
        path: "admin",
        element: <LoginGate Component={AdminIndex} userTypes={[AUserTypes.Admin]} />,
        children: [
          {
            path: "prospects/:salesCycleId",
            Component: AdminProspects,
          },
          {
            path: "leads",
            Component: Leads,
          },
          {
            path: "matching",
            Component: MatchingIndex,
            children: [
              {
                path: ":tab",
                children: [
                  {
                    index: true,
                    element: <Matching />,
                  },
                  {
                    path: "detail/:id",
                    children: [
                      {
                        index: true,
                        element: <MatchingDetails />,
                      },
                      {
                        path: "assignment/:assignmentId",
                        element: <MatchingDetails />,
                      },
                    ],
                  },
                ],
              },
            ],
          },
          {
            path: "sellings",
            Component: SellingsIndex,
            children: [
              {
                path: ":tab",
                children: [
                  {
                    index: true,
                    element: <Sellings />,
                  },
                ],
              },
            ],
          },
          {
            path: "sellings/:salesCycleId/reports/:metric?",
            Component: AdminReports,
          },
        ],
      },
    ],
  },
])

const Router = () => <RouterProvider router={router} />

export default Router
