import {z} from "zod"

import {i18n} from "../../i18n.ts"
import {ASalesCycleYearsOfMarkets} from "../../services/types.generated.ts"
import {validateNativeEnum, validateNonemptyString, validateUrl} from "../../utils/validation.ts"
import {TStep} from "./types.ts"

export const stepBenefits = {
  name: i18n.t("SalesStrategy_Steps_Benefits"),
  description: "",
  href: "benefits",
  fields: ["product_benefits"],
  validationSchema: z.object({}),
} as const satisfies TStep

export const stepElevatorPitch = {
  name: i18n.t("SalesStrategy_Steps_ElevatorPitch"),
  description: "",
  href: "elevator-pitch",
  fields: ["elevator_pitch"],
  validationSchema: z.object({}),
} as const satisfies TStep

export const stepMarketPresence = {
  name: i18n.t("SalesStrategy_Steps_MarketPresence"),
  description: "",
  href: "market-presence",
  fields: ["years_of_market"],
  validationSchema: z.object({years_of_market: validateNativeEnum(ASalesCycleYearsOfMarkets)}),
} as const satisfies TStep

export const stepReferences = {
  name: i18n.t("SalesStrategy_Steps_References"),
  description: "",
  href: "references",
  fields: ["references", "articles_links"],
  validationSchema: z.object({}),
} as const satisfies TStep

export const maxProductUniqueItemsCount = 5

export const stepUniqueness = {
  name: i18n.t("SalesStrategy_Steps_Uniqueness"),
  description: "",
  href: "uniqueness",
  fields: ["product_uniques"],
  validationSchema: z.object({
    product_uniques: z
      .array(validateNonemptyString())
      .max(
        maxProductUniqueItemsCount,
        i18n.t("SalesStrategy_StepUniqueness_ValidationMessage", {count: maxProductUniqueItemsCount})
      ),
  }),
} as const satisfies TStep

export const stepWeb = {
  name: i18n.t("SalesStrategy_Steps_WebAndPresentation"),
  description: "",
  href: "web",
  fields: ["website", "appendable_attachments"],
  validationSchema: z.object({website: validateUrl(), appendable_attachments: z.any()}),
} as const satisfies TStep

export const stepAdditionalInformation = {
  name: i18n.t("SalesStrategy_Steps_AdditionalInformation"),
  description: "",
  href: "additional-information",
  fields: ["additional_strategy_description", "sales_scripts"],
  validationSchema: z.object({}),
} as const satisfies TStep
