import {z} from "zod"

import {validateNonemptyString, validatePassword} from "../../../utils/validation.ts"

export const steps = [
  {
    validationSchema: z.object({
      companyName: validateNonemptyString(),
      phoneNumber: validateNonemptyString(),
    }),
  },
  {
    validationSchema: z.object({
      password: validatePassword(),
    }),
  },
] as const satisfies Array<{validationSchema: z.ZodObject<z.ZodRawShape>}>

export enum EPage {
  DETAILS,
  PASSWORD,
}

export const mergedValidationSchema = steps[EPage.DETAILS].validationSchema.merge(
  steps[EPage.PASSWORD].validationSchema
)
