import {i18n} from "../../../i18n.ts"
import {AUserTypes} from "../../../services/types.generated.ts"

export const UserTypes = {...AUserTypes, Anonymous: "Anonymous"} as const

export type TUserType = (typeof UserTypes)[keyof typeof UserTypes]

export const navigation = {
  [UserTypes.Admin]: [
    {name: i18n.t("T_Sales plans"), href: "/admin/sellings"},
    {name: i18n.t("T_Matching"), href: "/admin/matching"},
    {name: i18n.t("Leads_HeaderItem"), href: "/admin/leads"},
  ],
  [UserTypes.CompanyUser]: [
    {
      name: i18n.t("T_Dashboard"),
      href: "/dashboard",
    },
    {
      name: i18n.t("T_How it works"),
      href: "/how-it-works",
    },
    {
      name: i18n.t("T_Pricing"),
      href: i18n.t("Url_Pricing"),
      isExternal: true,
    },
  ],
  [UserTypes.SalesPerson]: [
    {
      name: i18n.t("T_Dashboard"),
      href: "/dashboard",
    },
  ],
  [UserTypes.Anonymous]: [],
  [UserTypes.SaasSalesPerson]: [
    {
      name: i18n.t("T_Dashboard"),
      href: "/dashboard",
    },
  ],
  [AUserTypes.SaasCompanyUser]: [
    {
      name: i18n.t("T_Dashboard"),
      href: "/dashboard",
    },
  ],
} as const satisfies {
  [userType in TUserType]: ReadonlyArray<{
    name: string
    href: string
    isExternal?: boolean
  }>
}
