export type TJSONValue = number | string | boolean | null | TJSONValue[] | {[key: string]: TJSONValue}

export type TLSItem<T extends TJSONValue = any> = {version: number; data: T}
export type TLSItemWithKey<T extends TJSONValue = any> = {key: TLSKey | string} & TLSItem<T>

const LS_PREFIX = "CloseRocket_"
const makeLSKey = (name: string) => `${LS_PREFIX}${name}`

export const CURRENT_LS_VERSIONS = {
  lastUser: 2,
  language: 2,
  features: 1,
} as const satisfies {[key: string]: number}

export type TLSKey = keyof typeof CURRENT_LS_VERSIONS

export function getLSItem<T extends TJSONValue = any>(key: TLSKey): TLSItem<T> | undefined {
  const stringItem = globalThis.localStorage?.getItem(makeLSKey(key))

  if (stringItem == null) {
    return undefined
  }

  return JSON.parse(stringItem)
}

export function setLSItem(key: TLSKey, data: TLSItem["data"], version = CURRENT_LS_VERSIONS[key] ?? -1) {
  globalThis.localStorage?.setItem(makeLSKey(key), JSON.stringify({version, data} satisfies TLSItem))
}

export function deleteLSItem(key: TLSKey) {
  globalThis.localStorage?.removeItem(makeLSKey(key))
}

function getAllLSItemKeys(): string[] {
  return Object.keys(globalThis.localStorage ?? {})
    .filter(key => key.startsWith(LS_PREFIX))
    .map(key => key.substring(LS_PREFIX.length))
}

export function getAllLSItems(): TLSItemWithKey[] {
  return getAllLSItemKeys().reduce((items, key) => {
    const item = getLSItem(key as TLSKey)

    if (item) {
      items.push({key, ...item})
    }

    return items
  }, [] as TLSItemWithKey[])
}

export function deleteAllLSItems() {
  getAllLSItemKeys().forEach(key => deleteLSItem(key as TLSKey))
}
